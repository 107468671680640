import React, { useEffect } from "react"

const SubscribeForm = ({ open }) => {
  const openSubscribeForm = () => {
    document.querySelector(".subscribeForm").classList.toggle("hidden")
    document.querySelector(".contactHead").classList.toggle("hidden")
    document.querySelector(".openSubscribeForm").classList.toggle("hidden")
    document.querySelector(".sidepanel__logo").classList.toggle("hidden")
    //select the second btn!!
    document.querySelectorAll(".btn-back")[1].classList.toggle("hidden")
  }

  useEffect(() => {
    open === "subscribeForm" && openSubscribeForm()
  }, [])

  return (
    <>
      <div className="subscribeHead">
        <div className="btn-back hidden" onClick={openSubscribeForm}>
          <span className="btn-back__arrow"></span>
        </div>
        <h2>S'abonner à la newsletter</h2>
        <p>
          Inscrivez-vous à ma newsletter pour pouvoir accéder en exclusivité aux
          premières partitions gratuites et être tenus au courant de mes
          nouvelles publications&nbsp;!
        </p>
        <button className="openSubscribeForm" onClick={openSubscribeForm}>
          Newsletter
        </button>
      </div>
      <div className="contact__container subscribeForm hidden">
        <form
          name="subscribe"
          className="contact__form"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/subscribed"
        >
          <p className="hidden">
            <label>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
          </p>
          <input type="hidden" name="form-name" value="subscribe" />
          <input type="email" name="email" placeholder="email" />

          <button type="submit">Newsletter</button>
        </form>
        <p>
          <i>
            J'utiliserai vos coordonnées uniquement pour vous informer sur des
            publications liées au projet "Brassens à la guitare" et m'engage à
            ne jamais les transmettre à des tiers.
          </i>
        </p>
      </div>
    </>
  )
}

export default SubscribeForm
