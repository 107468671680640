import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Biography = ({ toggleOpen, openContact, isOpen }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          social {
            name
            url
          }
        }
      }
      bioImage: file(relativePath: { eq: "pipe.png" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const expand = () => {
    document.querySelector(".expand-button").classList.toggle("hidden")
    document.querySelector(".collapse-button").classList.toggle("hidden")

    var content = document.querySelector(".biography__text__collapse")
    var parent = document.querySelector(".homepage__panel")

    content.classList.toggle("expanded")

    if (content.style.maxHeight) {
      content.style.maxHeight = null
      parent.style.overflow = "inherit"
    } else {
      content.style.maxHeight = content.scrollHeight + 20 + "px"
      parent.style.overflow = "overlay"
    }
  }

  const youtubeUrl = data.site.siteMetadata.social.filter(
    social => social.name === "youtube"
  )[0].url
  if (!isOpen) return null
  return (
    // <div className="homepage__about">
    <div className="biography">
      <div className="btn-close" onClick={() => toggleOpen("biography")}>
        <span className="btn-close__cross"></span>
      </div>
      <div className="sidepanel__logo">
        <Img fluid={data.bioImage.childImageSharp.fluid} />
      </div>
      <h2 className="biography__title">Le projet</h2>
      <p className="biography__text">
        Brassens...à la guitare&nbsp;? Ça sonne comme une évidence&nbsp;! Et
        pourtant, beaucoup de guitaristes pourraient témoigner de la difficulté
        de trouver des partitions fiables et surtout{" "}
        <i>adaptées à la guitare</i> des chansons de Brassens. Ah, les
        innombrables partitions commerciales «&nbsp;spécial piano&nbsp;»
        (bizarre pour Brassens, non&nbsp;?), ou encore les nombreuses versions
        sur internet, toutes plus ou moins approximatives... <br /> Voilà le
        point de départ d’un travail rigoureux (et à vrai dire un peu
        monomaniaque&nbsp;!) entamé de longue date&nbsp;&nbsp;: proposer des
        versions <i>vraiment</i> pour guitare des chansons de Brassens.{" "}
        <a className="expand-button" onClick={expand} href="#">
          [lire plus]
        </a>
      </p>
      <div className="biography__text__collapse content">
        <p className="biography__text">
          Pour cela, j’ai commencé par transcrire fidèlement, en m’appuyant sur
          de nombreuses sources audio et vidéo, les grilles d’accords (dans leur
          réalisation propre à Brassens), les rythmiques ou encore les variantes
          de la guitare de Brassens lui-même, afin de retrouver tout ce qui fait
          ce «&nbsp;son&nbsp;» Brassens si caractéristique. Puis, en utilisant
          mes ressources de guitariste classique, j’y ai rajouté des
          arrangements, pour guitare seule, issus des motifs principaux de
          seconde guitare et de contrebasse (les deux instruments qui
          accompagnent Brassens dans toutes ses chansons), ces motifs qui font
          vraiment partie de l’identité d’une chanson au point qu’il est souvent
          naturel de les fredonner comme des notes sans paroles&nbsp;: la petite
          ligne de seconde guitare dans le couplet des <i>Bancs publics</i>,
          l’interlude de <i>Je me suis fait tout petit</i> etc.
        </p>
        <p className="biography__text">
          De ce travail, inédit à ce jour, j’en ai tiré des partitions de forme
          hybride, mêlant grilles d’accords et écriture en
          notes/tablatures&nbsp;: vous pourrez les découvrir sur ce site grâce à
          une inscription gratuite à ma{" "}
          <a href="#" onClick={() => openContact("subscribeForm")}>
            newsletter
          </a>
          . Convaincu que la musique de Brassens (souvent mésestimée) mérite
          d’être accessible avec qualité et précision au plus grand nombre, je
          me suis également lancé dans{" "}
          <a href={youtubeUrl} target="_blank">
            une chaîne Youtube
          </a>
          , avec des interprétations de chansons mais aussi des tutoriels
          détaillés reprenant tous les aspects techniques de mes arrangements.
        </p>
        <p className="biography__text">
          Avec ce projet multiforme et toujours en évolution, j’espère
          contribuer à faire (re)découvrir à sa juste valeur les œuvres de ce
          chansonnier intemporel.
        </p>
        <p className="biography__text">
          N’hésitez pas à me{" "}
          <a href="#" onClick={() => openContact("contactForm")}>
            contacter
          </a>{" "}
          pour toute question sur le projet, ou tout simplement pour
          échanger&nbsp;!
        </p>
      </div>
      <a className="collapse-button hidden" onClick={expand} href="#">
        [masquer]
      </a>
      <h2 className="biography__title">&Agrave; propos de moi</h2>
      <p className="biography__text">
        Guitariste classique de formation (vous pouvez m’écouter dans plusieurs
        groupes, notamment{" "}
        <a
          href="https://www.youtube.com/channel/UCpz0zb2OS6mPX9BI9IROTYw"
          target="_blank"
          rel="noopener noreferrer"
        >
          ici
        </a>{" "}
        ou{" "}
        <a
          href="https://www.youtube.com/channel/UCXSY4VW4YC0hp8_vCQHElkQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          là
        </a>
        ), je suis également arrangeur et professeur de guitare, basé à
        Bruxelles, et un passionné depuis l’enfance par la chanson française
        sous toutes ses formes&nbsp;!
      </p>
    </div>
    // </div>
  )
}

export default Biography