import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import HomepageImage from "../components/image"
import Biography from "../components/bio"
import Contact from "../components/contact"
import { useEffect } from "react"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark {
        frontmatter {
          video_url
          video_url_embedded
        }
      }
      placeholderImage: file(relativePath: { eq: "kcb_icon_white.png" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    panelToggle()
  })

  const handleButton = (e, openContactForm) => {
    const name = (e.target && e.target.name) || e
    if (toggle[name]) {
      handleClose(name)
    } else {
      setToggle({
        isOpen: true,
        [name]: !toggle[name],
        openForm: openContactForm,
      })
    }
  }

  const handlePanelContent = name => {
    setTimeout(() => {
      setToggle(prevState => ({
        ...prevState,
        [name]: !toggle[name],
      }))
    }, 150)
  }

  const handleClose = name => {
    setToggle(prevState => ({
      ...prevState,
      isOpen: false,
    }))
    handlePanelContent(name)
  }

  const panelToggle = () => {
    if (toggle.isOpen) {
      document.querySelector(".homepage__panel").classList.add("open")
    } else {
      document.querySelector(".homepage__panel").classList.remove("open")
    }
  }

  return (
    <Layout>
      <SEO title="Home" />
      <div className="homepage">
        <div className="homepage__visual">
          <HomepageImage />
          <div className="homepage__panel">
            <Biography
              id="biography"
              isOpen={toggle.biography}
              toggleOpen={name => handleClose(name)}
              openContact={withOpenedForm =>
                handleButton("contact", withOpenedForm)
              }
            />
            <Contact
              id="contact"
              isOpen={toggle.contact}
              toggleOpen={name => handleClose(name)}
              openForm={toggle.openForm}
            />
          </div>
        </div>
        <div className="homepage__text">
          <div className="homepage__logo">
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
          </div>
          <div className="homepage__flex-content">
            <div>
              <h1 className="homepage__flex-content__title">
                Brassens à la guitare
              </h1>
            </div>
            <div>
              <h3 className="homepage__flex-content__subtitle">
                par Kamal Abdul-Malak
              </h3>
            </div>

            <div className="homepage__flex-content__buttons">
              <div>
                <button
                  id="biography"
                  className={
                    toggle.biography
                      ? "homepage__buttons__a active"
                      : "homepage__buttons__a"
                  }
                  name="biography"
                  onClick={handleButton}
                >
                  &Agrave; propos
                </button>
              </div>
              <div>
                <button
                  className={
                    toggle.contact
                      ? "homepage__buttons__a active"
                      : "homepage__buttons__a"
                  }
                  name="contact"
                  onClick={handleButton}
                >
                  Contactez-moi
                </button>
              </div>
            </div>
            <div className="homepage__flex-content__video">
              <a
                href={data.markdownRemark.frontmatter.video_url}
                target="_blank"
                className="homepage__flex-content__video__link"
                rel="noopener noreferrer"
              >
                Voir la dernière vidéo ici
              </a>
              <div className="homepage__flex-content__video__embedded">
                <iframe
                  title="dernière vidéo"
                  src={data.markdownRemark.frontmatter.video_url_embedded}
                  frameborder="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // DO NOTE REMOVE! needed for netlify BOTS to find the form // */}
      <form
        name="contact"
        data-netlify="true"
        netlify-honeypot="bot-field"
        action="/success"
        hidden
      >
        <input type="text" name="name" />
        <input type="email" name="email" />
        <textarea name="message"></textarea>
      </form>
      <form
        name="subscribe"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/subscribed"
        hidden
      >
        <input type="email" name="email" placeholder="email" />
      </form>
    </Layout>
  )
}

export default IndexPage
