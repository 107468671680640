import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ContactForm from "./contactForm"
import SubscribeForm from "./subscribeForm"

const Contact = ({ toggleOpen, isOpen, openForm }) => {
  const data = useStaticQuery(graphql`
    query {
      contactImage: file(relativePath: { eq: "banc.png" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  if (!isOpen) return null
  return (
    <div className="contact">
      <div className="btn-close" onClick={() => toggleOpen("contact")}>
        <span className="btn-close__cross"></span>
      </div>
      <div className="sidepanel__logo">
        <Img fluid={data.contactImage.childImageSharp.fluid} />
      </div>
      <ContactForm open={openForm} />
      <br />
      <SubscribeForm open={openForm} />
    </div>
  )
}

export default Contact
