import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const HomepageImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "homepage.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      className="homepage__image_background"
      fluid={data.placeholderImage.childImageSharp.fluid}
      objectPosition="left center"
      // style={{ objectPosition: "bottom" }}
    />
  )
}

export default HomepageImage
