import React, { useEffect } from "react"

const ContactForm = ({ open }) => {
  useEffect(() => {
    open === "contactForm" && openContactForm()
  }, [])

  const openContactForm = () => {
    document.querySelector(".contactForm").classList.toggle("hidden")
    document.querySelector(".subscribeHead").classList.toggle("hidden")
    document.querySelector(".openContactForm").classList.toggle("hidden")
    document.querySelector(".sidepanel__logo").classList.toggle("hidden")
    document.querySelector(".btn-back").classList.toggle("hidden")
  }

  return (
    <>
      <div className="contactHead">
        <div className="btn-back hidden" onClick={openContactForm}>
          <span className="btn-back__arrow"></span>
        </div>
        <h2>Me contacter</h2>
        <p>
          N’hésitez pas à m'écrire pour toute question sur le projet, ou tout
          simplement pour échanger&nbsp;!
        </p>
        <button className="openContactForm" onClick={openContactForm}>
          Contact
        </button>
      </div>
      <div className="contact__container contactForm hidden">
        <form
          name="contact"
          className="contact__form"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/subscribed"
        >
          {/* <input type="hidden" name="form-name" value="Contact Form" /> */}
          <p className="hidden">
            <label>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
          </p>
          <input type="hidden" name="form-name" value="contact" />
          <input type="text" name="name" placeholder="nom" />

          <input type="email" name="email" placeholder="email" />

          <textarea
            className="contact__form__text"
            name="message"
            placeholder="votre message"
          ></textarea>
          <button type="submit">Contact</button>
        </form>
      </div>
    </>
  )
}

export default ContactForm
